<template>
  <div>
    <h1>Admin Page</h1>
    <button @click="showCreateProjectForm">Create Project</button>
    <button @click="showEditProjectList">Edit Project</button>
    <button @click="showAddMediaForm">Add Media</button>

    <!-- Render the appropriate component based on the current action -->
    <CreateProjectForm
      v-if="currentAction === 'create'"
      @projectSaved="handleProjectSaved"
    />
    <EditProjectForm
      v-if="currentAction === 'edit'"
      :projectCode="selectedProjectCode"
      @projectSaved="handleProjectSaved"
    />
    <ProjectList
      v-if="currentAction === 'list'"
      @projectSelected="loadProjectForEdit"
    />
    <AddMediaForm
      v-if="currentAction === 'addMedia'"
      @mediaUploaded="handleMediaUploaded"
    />
  </div>
</template>

<script>
import CreateProjectForm from "./CreateProjectForm.vue";
import EditProjectForm from "./EditProjectForm.vue";
import ProjectList from "./ProjectList.vue";
import AddMediaForm from "./AddMediaForm.vue";

export default {
  components: { CreateProjectForm, EditProjectForm, ProjectList, AddMediaForm },
  data() {
    return {
      currentAction: null,
      selectedProjectCode: null, // Store selected project code
    };
  },
  methods: {
    showCreateProjectForm() {
      this.currentAction = "create";
    },
    showEditProjectList() {
      this.currentAction = "list";
    },
    showAddMediaForm() {
      this.currentAction = "addMedia";
    },
    handleProjectSaved() {
      this.currentAction = null; // Go back to the initial view
    },
    handleMediaUploaded(url) {
      this.currentAction = null; // Go back to the initial view
      alert(`Media uploaded! URL: ${url}`);
    },
    loadProjectForEdit(projectCode) {
      this.selectedProjectCode = projectCode; // Set the selected project code
      this.currentAction = "edit";
    },
  },
};
</script>
