<template>
  <div>
    <h2>Create Project</h2>
    <form @submit.prevent="saveProject">
      <div>
        <label>Project Code:</label>
        <input v-model="projectCode" required />
      </div>
      <div>
        <label>Page Template:</label>
        <p class="instructions">
          Please provide HTML and CSS code for your page template. Use the
          <code>&#123;&#123;media_placeholder&#125;&#125;</code> variable in the
          template where the image will be inserted. For example:
        </p>
        <pre class="code-example">
        &lt;div class="container"&gt;
          &lt;h1&gt;Your Page Title&lt;/h1&gt;
          &lt;img src="<code>&#123;&#123;media_placeholder&#125;&#125;</code>" alt="Media"&gt;
        &lt;/div&gt;
        </pre>
        <textarea v-model="pageTemplate" placeholder="Enter HTML + CSS here"></textarea>
      </div>
      <div>
        <label>Loading Template:</label>
        <p class="instructions">
          Please provide the HTML and CSS content that will be displayed while
          the media is loading or if the media is not yet available. For
          example:
        </p>
        <pre class="code-example">
        &lt;div class="loading"&gt;
          &lt;h1&gt;Loading, please wait...&lt;/h1&gt;
        &lt;/div&gt;
        </pre>
        <textarea v-model="loadingTemplate" placeholder="Enter loading template HTML here"></textarea>
      </div>
      <button type="submit">Save Project</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectCode: "",
      pageTemplate: "",
      loadingTemplate: "",
    };
  },
  methods: {
    async saveProject() {
      const project = {
        projectCode: this.projectCode,
        pageTemplate: this.pageTemplate || null,
        loadingTemplate: this.loadingTemplate || null,
      };

      try {
        await this.$http.post("/api/projects/create", project); // Adjust the API endpoint
        this.$emit("projectSaved"); // Notify parent that the project has been saved
      } catch (error) {
        console.error("Error saving project:", error);
      }
    },
  },
};
</script>

<style scoped>
.instructions {
  font-style: italic;
  margin-bottom: 10px;
}

.code-example {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  margin-bottom: 10px;
}
</style>
